import { FC } from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Services from './components/Services';
import UwandaWallet from './components/UwandaWallet';
import TrackSpending from './components/TrackSpending';
import MultiplePayment from './components/MultiplePayments';
import Footer from './components/Footer';

const App: FC = () => {
  return (
    <div>
      <Navbar />
      <Hero />
      <Services />
      <UwandaWallet />
      <TrackSpending />
      <MultiplePayment />
      <Footer />
    </div>
  );
}

export default App;
