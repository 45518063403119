import { FC } from "react";
import GooglePlay from "../images/googleplay.png";
import TrackSpendingScreen from "../images/smartphone02.png";

const TrackSpending: FC = () => {
  return (
    <section className="max-w-md sm:max-w-xl md:max-w-4xl lg:max-w-6xl xl:max-w-7xl mx-auto font-mulish mt-10 lg:mt-56">
      {/* container mx-auto */}
      <div className="pl-4 lg:pl-2 py-10 lg:py-0 mb-10 md:mb-20" id="home">
        <div className="items-center grid md:grid-cols-2 lg:grid-cols-2">
          <div className="w-full lg:order-2">
            <div className="max-w-md md:max-w-xl lg:max-w-4xl lg:-mt-10">
              <h1 className="text-2xl font-extrabold text-[#2E1183] lg:text-3xl xl:text-5xl leading-snug hidden xl:block">
                Track your spending
              </h1>
              {/* Mobile */}
              <h1 className="text-4xl md:text-4xl font-extrabold text-black leading-snug md:leading-normal xl:hidden">
                <span className="text-[#2E1183]">Track your spending</span>{" "}
              </h1>
              <p className="text-lg text-[#333333] mt-10 mr-10 font-medium">
                Check your transactions and track your spending all in one
                place. Leave behind the accounting struggles for good.
              </p>

              <div className="lg:flex lg:space-x-3 pr-10 md:px-0 mt-5 lg:mt-10">
                <div className="w-48 lg:w-full">
                  <img
                    className="object-center object-cover"
                    src={GooglePlay}
                    alt="Google Play Button"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-10 md:mt-24 lg:mt-0 lg:-ml-5 w-full lg:order-1">
            <img
              className="w-full h-full object-center object-cover optimized lg:scale-150 pr-5"
              src={TrackSpendingScreen}
              alt="Track Spending Screen"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrackSpending;
