import { FC } from "react";
import GooglePlay from "../images/googleplay.png";
import WalletScreen from "../images/smartphone01.png";

const UwandaWallet: FC = () => {
  return (
    <section className="max-w-md sm:max-w-xl md:max-w-4xl lg:max-w-6xl xl:max-w-7xl mx-auto font-mulish mt-10 lg:mt-28" id="features">
      {/* container mx-auto */}
      <div className="pl-4 lg:pl-2 py-10 lg:py-0 mb-10 md:mb-20" id="home">
        <div className="items-center grid md:grid-cols-2">
          <div className="w-full">
            <div className="max-w-md md:max-w-xl lg:max-w-4xl lg:-mt-10">
              <h1 className="text-2xl font-extrabold text-[#2E1183] lg:text-3xl xl:text-5xl leading-snug mr-20 hidden xl:block">
                Uwanda wallet
              </h1>
              {/* Mobile */}
              <h1 className="text-3xl md:text-4xl font-extrabold text-black leading-snug md:leading-normal xl:hidden">
                <span className="text-[#2E1183]"> Uwanda wallet</span>{" "}
              </h1>
              <p className="text-lg text-[#333333] mt-10 mr-10 font-medium">
                You don’t have to switch between programs or providers to get
                things done. This also means fewer registrations, fewer
                incidences of providing payment details, and an overall
                reduction of friction.
              </p>

              <div className="lg:flex lg:space-x-3 pr-10 md:px-0 mt-5 lg:mt-10">
                <div className="w-48 lg:w-full">
                  <img
                    className="object-center object-cover"
                    src={GooglePlay}
                    alt="vector-line"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-10 md:mt-24 lg:mt-0 w-full">
            <img
              className="w-full h-full object-center object-cover optimized lg:-ml-32 pl-5 lg:scale-150 hidden lg:block"
              src={WalletScreen}
              alt="Uwanda Wallet Screen"
            />
            <img
              className="w-full h-auto object-center object-cover optimized lg:hidden"
              src={WalletScreen}
              alt="Uwanda Wallet Screen"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default UwandaWallet;
