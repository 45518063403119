import React, { FC, useState } from "react";
import GooglePlay from "../images/googleplay.png";
import Vector from "../images/vector.png";
import HeroImage from "../images/hero-image.png";
import Skeleton from "@mui/material/Skeleton";

const Hero: FC = () => {

  const [heroImage] = useState(HeroImage);

  return (
    <section className="max-w-md sm:max-w-xl md:max-w-4xl lg:max-w-6xl xl:max-w-7xl mx-auto font-mulish">
      {/* container mx-auto */}
      <div className="pl-4 lg:pl-6 py-10 lg:py-0  mb-20" id="home">
        <div className="items-center grid md:grid-cols-2 lg:grid-cols-2">
          <div className="w-full">
            <div className="max-w-md md:max-w-xl lg:max-w-4xl lg:-mt-10">
              <div className="flex items-center space-x-2 mb-3 lg:mb-5 text-[#53237C]">
                <img
                  className="object-center object-cover"
                  src={Vector}
                  alt="vector-line"
                />
                <span className="text-base font-medium">More than an app.</span>
              </div>

              <h1 className="text-3xl font-extrabold text-[#2E1183] lg:text-4xl xl:text-6xl leading-snug mr-20 hidden xl:block">
                One place to get
              </h1>
              <h1 className="text-3xl font-extrabold text-[#319385] lg:text-4xl xl:text-6xl leading-snug mr-20 mt-4 hidden xl:block">
                everything done.
              </h1>
              {/* Mobile */}
              <h1 className="text-5xl md:text-4xl font-extrabold text-black leading-snug md:leading-normal xl:hidden">
                <span className="text-[#2E1183]">One place to get</span>{" "}
                <span className="text-[#319385]">everything done.</span>
              </h1>

              <div className="lg:flex lg:space-x-3 pr-10 md:px-0 mt-5 lg:mt-12">
                <div className="w-48 lg:w-full">
                  <img
                    className="object-center object-cover"
                    src={GooglePlay}
                    alt="vector-line"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="mt-12 lg:mt-0 pr-4 w-full">
            {!heroImage ? (
              <Skeleton variant="rectangular" width={210} height={118} />
            ) : (
              <img
                className="lg:w-4/5 h-full object-center object-cover optimized transition-all"
                src={heroImage}
                alt="SmartPhones"
              />
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Hero);
