import { FC } from 'react';
import { FaHands } from 'react-icons/fa';
import { HiSearch, HiOutlineShoppingBag } from "react-icons/hi";

const Services: FC = () => {
  return (
    <section
      className="font-mulish bg-[#EADCF533] py-16 px-4 lg:px-0"
      id="services"
    >
      <div className="max-w-md lg:max-w-2xl mx-auto text-center">
        <h2 className="text-4xl lg:text-5xl text-[#2E1183] font-extrabold">
          Services we provide.
        </h2>
        <p className="mt-5 lg:mt-10 text-[#333333] text-base font-medium">
          We strive to make the world a better place, not just by what we do,
          but by how we do it. At Uwanda, we aggregate services that consider
          their impact on the environment.{" "}
        </p>
      </div>

      <section className="grid grid-cols-1 md:grid-cols-3 gap-6 md:gap-0 mt-24 font-mulish max-w-6xl mx-auto mb-10">
        <div className="bg-white p-10 rounded-lg max-w-md shadow-lg">
          <div className="flex justify-center">
            <div className="w-28 h-28 rounded-xl bg-[#6CE3D2] rotate-45 grid justify-center items-center">
              <FaHands className="-rotate-45 w-10 h-10" />
            </div>
          </div>
          <h3 className="text-2xl font-bold text-center mt-10">
            Specialized Healthcare
          </h3>
          <p className="mt-3 text-sm font-medium text-center px-10 mb-16">
            Consult with trusted doctors and specialists near you.
          </p>
        </div>
        <div className="bg-white p-7 rounded-lg max-w-md shadow-lg md:scale-125">
          <div className="flex justify-center">
            <div className="w-28 h-28 rounded-xl bg-[#AB99BB] rotate-45 grid justify-center items-center">
              <HiSearch className="-rotate-45 w-10 h-10" />
            </div>
          </div>
          <h3 className="text-2xl lg:text-3xl font-bold text-center mt-10">
            Vacant Properties
          </h3>
          <p className="mt-3 text-sm lg:text-base font-medium text-center px-2 mb-16">
            Get information about vacant properties.
          </p>
        </div>
        <div className="bg-white p-10 rounded-lg max-w-md shadow-lg">
          <div className="flex justify-center">
            <div className="w-28 h-28 rounded-xl bg-[#6CE3D2] rotate-45 grid justify-center items-center">
              <HiOutlineShoppingBag className="-rotate-45 w-10 h-10" />
            </div>
          </div>
          <h3 className="text-2xl font-bold text-center mt-10">Order Food</h3>
          <p className="mt-3 text-sm font-medium text-center px-10 mb-16">
            Our Job is to shorten the time spent from ordering a meal in a
            restaurant to when it’s delivered.
          </p>
        </div>
      </section>
    </section>
  );
}

export default Services