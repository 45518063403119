import { useState, useEffect, FC } from "react";
import Logo from "../images/LOGO.png";
import GooglePlay from "../images/googleplay.png";
import FooterBlock from '../images/footerimg.png';

const Footer: FC = () => {
  const handleCurrentYear = () => {
    const year: any = document.querySelector(
      "#current-year"
    ) as HTMLElement | null;
    year.innerHTML = new Date().getFullYear();
  };

  useEffect(() => {
    handleCurrentYear();
  });

  const [activeHome, setActiveHome] = useState(true);
  const [activeService, setActiveService] = useState(false);
  const [activeFeature, setActiveFeature] = useState(false);

  const resetActive = () => {
    setActiveHome(false);
    setActiveService(false);
    setActiveFeature(false);
  };
  const handleActive = (arg: string) => {
    resetActive();
    if (arg === "home") {
      setActiveHome(true);
    } else if (arg === "features") {
      setActiveFeature(true);
    } else if (arg === "services") {
      setActiveService(true);
    }
  };

  return (
    <footer>
      <img
        className="object-cover object-center md:pt-10"
        src={FooterBlock}
        alt="Uwanda Logo"
      />
      <div
        className="py-5 lg:py-10 max-w-md sm:max-w-xl md:max-w-4xl lg:max-w-6xl xl:max-w-7xl mx-auto lg:pb-16 font-mulish px-4 lg:px-3"
        id="contact"
      >
        <div className="md:flex justify-between items-center mt-4">
          <a href="#home" className="flex items-center">
            <img
              className="object-cover object-center w-28 md:w-full h-auto md:h-12 optimized"
              src={Logo}
              alt="Uwanda Logo"
            />
          </a>
          <ul className="flex items-center space-x-3 lg:space-x-10 mt-5 lg:mt-0 font-mulish text-base font-medium">
            <li onClick={() => handleActive("home")}>
              <a
                href="#home"
                className={`block py-2 pr-4 pl-3 hover:text-[#319385] md:p-0 ${
                  activeHome
                    ? "text-[#319385] border-b-4 border-[#319385]"
                    : "text-[#333333]"
                }`}
                aria-current="page"
              >
                Home
              </a>
            </li>
            <li onClick={() => handleActive("services")}>
              <a
                href="#services"
                className={`block py-2 pr-4 pl-3 hover:text-[#319385] md:p-0 ${
                  activeService
                    ? "text-[#319385] border-b-4 border-[#319385]"
                    : "text-[#333333]"
                }`}
              >
                Services
              </a>
            </li>
            <li onClick={() => handleActive("features")}>
              <a
                href="#features"
                className={`block py-2 pr-4 pl-3 hover:text-[#319385] md:p-0 ${
                  activeFeature
                    ? "text-[#319385] border-b-4 border-[#319385]"
                    : "text-[#333333]"
                }`}
              >
                Features
              </a>
            </li>
          </ul>
        </div>

        <div className="grid md:flex justify-between items-center lg:mt-10">
          <div className="md:w-56 lg:flex lg:space-x-5 mt-7 lg:mt-0">
            <a href="#googleplaystore" className="flex items-center">
              <img
                className="object-cover object-center"
                src={GooglePlay}
                alt="Uwanda Logo"
              />
            </a>
          </div>
          <div className="grid gap-4 md:flex justify-between items-center md:space-x-10 md:mt-5 lg:mt-0 text-base font-medium">
            <div className="mt-5 md:mt-0">
              <div className="md:flex items-center">
                <p className="text-[#319385] mr-2 text-lg md:text-base mb-2 md:mb-0 font-medium">
                  Contact Us:
                </p>
                <span className="mb-1 lg:mb-0 text-[#333333]">
                  hello@uwanda.africa
                </span>
              </div>
              <p className="md:text-end text-[#333333]">(254)700-000000</p>
            </div>
            <div>
              <div className="md:flex items-center">
                <p className="text-[#319385] mr-2 text-lg lg:text-base mb-2 lg:mb-0 font-medium">
                  Legal:
                </p>
                <span className="mb-1 lg:mb-0 text-[#333333]">
                  Privacy Policy
                </span>
              </div>
              <p className="md:text-end text-[#333333]">Terms of Use</p>
            </div>
          </div>
        </div>

        <div className="md:flex justify-between items-center mt-8 text-base font-medium text-[#333333]">
          <ul className="flex items-center space-x-3 lg:space-x-7">
            <li>Facebook</li>
            <li>YouTube</li>
            <li>Instagram</li>
            <li>Twitter</li>
          </ul>
          <p className="mt-2 lg:mt-0">
            © <span id="current-year"></span> Uwanda Kenya or its affiliates
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
